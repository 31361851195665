import React, { useState, useEffect, useContext, useRef } from "react";
import styles from "../styles/TrackPage.module.css";
import config from "../utils/config";
import randomDefaultImage from "../utils/RandomDefaultImage";
import { FaPlay, FaPause } from "react-icons/fa";
import { useGlobalPlayer } from "../utils/GlobalPlayerContext";
import CustomButton from "../components/CustomButton/CustomButton";
import { BiAddToQueue } from "react-icons/bi";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { FaLink } from "react-icons/fa6";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getMultiTrack } from "../service/TrackService";
import { similarTracks } from "../service/Tracks";
import { trackInPack } from "../service/Packs";
import setMetaTags from "../utils/SeoService";
import { FiArrowUpRight } from "react-icons/fi";
import {
  IoIosCheckmark,
  IoIosClose,
  IoMdCloseCircle,
  IoIosArrowDown,
} from "react-icons/io";
import CleanSearchKey from "../utils/CleanSearchKey";
import Carousel from "../components/Carousel/Carousel";
import TrackCard from "../components/CardsComponent/TrackCard/TrackCard";
import PackCard from "../components/CardsComponent/PackCard/PackCard";
import { IoIosArrowForward } from "react-icons/io";
import { ToastContext } from "../utils/ToastContext";
import { useDispatch, useSelector } from "react-redux";
import getDirectLink from "../utils/AudioUrlFormated";
import { putCart } from "../service/TransactionService";
import { addToCart } from "../utils/redux/cartSlice"; // temp access of clear cart for buy now function
import {
  createUserLike,
  getTrackLike,
  createCollection,
  trackCollection,
  getUserCollections,
} from "../service/UserService";
import { LuPlus } from "react-icons/lu";
import RequestLicense from "./RequestLicense";
import restrictionUtils from "../utils/data/restrictions";
import { useHandleShareClick } from "../utils/Share";
import useMixpanel from "../service/MixpanelService";
import Login from "./Login";
import { MdOndemandVideo } from "react-icons/md";
import { FaExternalLinkAlt } from "react-icons/fa";

const TrackPage = () => {
  const [trackData, setTrackData] = useState([]); // state to hold the fetched track data
  const { name, trackCode } = useParams(); // gettign the data from navigated route
  const [useCaseTracks, setUseCaseTracks] = useState([]); // use cases tracks
  const [artistTracks, setArtistTracks] = useState([]); // artist tracks
  const [eventTracks, setEventTracks] = useState([]); // events tracks
  const [packCard, setPackCards] = useState([]); // similar track packs
  const [createPlaylistPopup, setCreatePlaylistPopup] = useState(false); // state to display popup of create playlist
  const [createInputPlaylist, setCreateInputPlaylist] = useState(false); // state to manage to display the input box for create playlist
  const [playlists, setPlaylists] = useState([]); // State for managing the existing user playlist info
  const [inputPlaylistValue, setInputPlaylistValue] = useState(""); // state to manage the input value of the new playlist create value
  const [trackLikedStatus, setTrackLikeStatus] = useState("N"); // state to manage the track liked status and display the icon accordingly
  const [isRequestOpen, setIsRequestOpen] = useState(false); // toggle cart modal
  const [licenseData, setIsLicenseData] = useState(null); // state to manage the request quote data for request license modal
  const [activeTab, setActiveTab] = useState("Usage Info"); // state to manage the info display
  const dispatch = useDispatch(); // Hook to dispatch actions
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const [ownerCode, setOwnerCode] = useState(null); // Default state is null using owner code for displaying restrictions data against the track
  const [isModalOpen, setIsModalOpen] = useState(false); // toggle login modal

  const navigate = useNavigate(); // Hook to navigate programmatically // temp access for the buy now function

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // checking the auth state while adding in the cart

  const { handleShareClick } = useHandleShareClick(); // Use the custom hook
  const { trackEvent } = useMixpanel(); // Use Mixpanel service

  const [loadingCart, setLoadingCart] = useState(false); // Add loading state for cart
  const [loadingBuyNow, setLoadingBuyNow] = useState(false); // Add loading state for buy now

  const [videoModal, setVideoModal] = useState(false); // show and hide the video modal
  const [isExpanded, setIsExpanded] = useState(false); // view more btn in mobile view

  // calling api the function when the trackCode changes
  useEffect(() => {
    const fetchData = async () => {
      // const startTime = performance.now();
      try {
        const response = await getMultiTrack([trackCode]);
        // console.log(response, "response");
        if (response.length === 0) {
          navigate("/404"); // Navigate to the 404 page if data is empty
          return;
        }
        const data = response.data || [];
        // console.log(data.track, "data");
        setTrackData(data.tracks[0]); // Set the track object in the state
        // console.log(data, "trackData");
        setOwnerCode(data.tracks?.[0].ownerCode ?? null); // Update ownerCode, fallback to `null`
        // console.log(data.tracks?.[0].name_slug, "slug val", name, "url name");

        // Ensure the correct slug is set in the URL
        if (data.tracks[0] && data.tracks?.[0].name_slug !== name) {
          // console.log("inside if");

          navigate(
            `/tracks/${CleanSearchKey(data.tracks?.[0].name)}/${trackCode}`,
            { replace: true }
          );
        }
        await fetchTrackLikeStatus();
      } catch (error) {
        // console.error("Error fetching track data:", error);
      } finally {
        // const endTime = performance.now();
        // console.log(`Fetch data took ${endTime - startTime} milliseconds`);
      }
    };
    fetchData();
  }, [trackCode]);

  // console.log(trackData, "trackdata");

  // function to set the seo data
  const seoData = {
    title: `${trackData?.name} | Song License | Hoopr Smash`,
    description: `License ${trackData?.name} now on Hoopr Smash. Buy now & access to our comprehensive royalty free music for better content engagement.`,
    ogImage: `${config.s3imgUrl}web/tracks/${trackCode}.webp`,
    canonical: window.location.href,
    // lang: "en",
  };
  setMetaTags(seoData);

  // Destructure the global player context
  const { isPlaying, setIsPlaying, showPlayer, playerData } = useGlobalPlayer();

  // console.log(playerData.trackCode, trackCode, "128");

  // getting item qyt from redux for the same track if already added
  const items = useSelector((state) => state.cart.items); // Redux selector

  // Utility to fetch item quantity from Redux state
  const getItemQuantity = (trackCode) =>
    items.find((item) => item.code === trackCode)?.quantity || 0;

  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if it's mobile or tablet
  const isMobile = windowWidth <= 425;
  const isTablet = windowWidth <= 768;

  // Determine restriction keys based on `ownerCode`
  const { restrictions, ownerMap } = restrictionUtils;
  const restrictionKeys =
    ownerCode && ownerMap[ownerCode] ? ownerMap[ownerCode] : ownerMap.default;

  const containerRef = useRef(null);
  const [visibleCount, setVisibleCount] = useState(restrictionKeys.length); // Set to full initially

  // to check the restricted data for rendering in pricing section
  useEffect(() => {
    if (!containerRef.current) return;

    requestAnimationFrame(() => {
      const updateVisibleCount = () => {
        if (!containerRef.current) return;

        const containerWidth = containerRef.current.clientWidth;
        const cards = Array.from(containerRef.current.children).filter(
          (child) => child.classList.contains(styles.presCard)
        );

        if (cards.length === 0) return; // Prevent measuring empty content

        let count = 0;
        let totalWidth = 0;

        for (let card of cards) {
          const cardWidth = card.offsetWidth;
          if (totalWidth + cardWidth > containerWidth) break;
          totalWidth += cardWidth;
          count++;
        }

        // console.log("Updated Visible Count:", count);
        setVisibleCount(count);
      };

      // Ensure elements exist before measuring
      setTimeout(updateVisibleCount, 0); // Slight delay to ensure rendering

      const resizeObserver = new ResizeObserver(updateVisibleCount);
      resizeObserver.observe(containerRef.current);

      return () => resizeObserver.disconnect();
    });
  }, [restrictionKeys]); // Runs when restrictionKeys change

  const hiddenCount = restrictionKeys.length - visibleCount;
  // console.log("Hidden Count:", hiddenCount);

  // api call tracks based on categories (api is from new service file)
  useEffect(() => {
    if (!trackData) return;
    setUseCaseTracks([]);
    setEventTracks([]);
    setArtistTracks([]);

    const fetchSimilarTracks = async () => {
      // const genre = trackData?.filters?.subFilters?.subgenre?.[0]?.name;
      // const mood = trackData?.filters?.mood?.[0]?.name;
      const useCase = trackData?.filters?.usecase?.[0]?.name || "";
      const artists =
        trackData?.primaryArtists
          ?.map((artist) => artist.artistName)
          .join(", ") || "";
      const events = trackData?.events?.[0] || "";

      const createPayload = async (params) => ({
        asset_type: "music",
        artists: [],
        bpm: {},
        genres: params.genre ? [await CleanSearchKey(params.genre)] : [],
        languages: [],
        moods: params.mood ? [await CleanSearchKey(params.mood)] : [],
        playlists: [],
        sfxcategories: [],
        sfxsubcategories: [],
        subgenres: [],
        usecases: params.useCase ? [await CleanSearchKey(params.useCase)] : [],
      });

      try {
        // if (genre || mood || useCase) {
        //   const payload = await createPayload({ genre, mood, useCase });
        //   setSimTracks(await similarTracks(payload));
        // }

        if (useCase) {
          const payload = await createPayload({ useCase });
          setUseCaseTracks(await similarTracks(payload, "", trackCode));
        }

        if (artists) {
          const payload = await createPayload({});
          setArtistTracks(await similarTracks(payload, artists, trackCode));
        }

        if (events) {
          const payload = await createPayload({});
          setEventTracks(await similarTracks(payload, events, trackCode));
        }
      } catch (error) {
        console.error("Error fetching similar tracks:", error);
      }
    };

    fetchSimilarTracks();
  }, [trackData]);

  // api call for tracks in packs
  useEffect(() => {
    const fetchPacks = async () => {
      setPackCards([]);
      try {
        let packCards = await trackInPack(trackCode);
        // console.log(packCard);

        if (packCard) {
          packCards = packCards.slice(0, 3);
          setPackCards(packCards);
        } else {
          setPackCards([]);
        }
      } catch (error) {
        // console.log(error, "error");
      }
    };
    fetchPacks();
  }, [trackData]);

  // calc the discount price and display in ui
  const { costPrice, sellingPrice } = trackData?.SKU?.[0] || {};
  const discountPercent =
    costPrice && sellingPrice
      ? `${(((costPrice - sellingPrice) / costPrice) * 100).toFixed(0)}%`
      : ""; // If either value is missing, return an empty string

  // track allowed details
  const allowed = {
    Y: [
      // "Organic Short-form Video License Use Includes",
      "Usage in one short form video asset ONLY",
      "Video can be published on up to 3 platforms",
      "Can be used and published by a SINGLE brand only",
    ],
    N: [
      "Use in video formats beyond organic short-form videos",
      "Use in promoted ads",
      "Use in ads that include celebrities",
      "Use in influencer marketing campaigns",
    ],
  };

  // track details - filter, about,descrption etc
  const aboutSong = [
    {
      title: "Song Credits",
      description: `From "${trackData.albumDetails?.title || ""}" by  ${
        trackData.trackRoleArtists
          ?.map((artist) => artist.artistName)
          .join(", ")
          ? " " +
            trackData.trackRoleArtists
              .map((artist) => artist.artistName)
              .join(", ")
          : ""
      }${trackData.releaseYear ? ` | ${trackData.releaseYear}` : ""} | ${
        trackData.ownerName || ""
      }`,
    },
    {
      title: "About",
      description: trackData.description || "",
    },
    {
      title: "Type",
      description: trackData.type
        ? trackData.type.charAt(0).toUpperCase() + trackData.type.slice(1)
        : "",
    },
    {
      title: "Category",
      pills: trackData.filters?.usecase?.map((usecase) => usecase.name) || [], // Map mood from filters
    },
    {
      title: "Genres",
      // pills: trackData.filters?.subFilters?.subgenre?.map(
      pills: trackData.filters?.genre?.map((genre) => genre.name) || [""], // Map genre from filters
    },
    {
      title: "Events",
      pills: trackData.events?.map((events) => events) || [""], // Map instrument as events
    },
    {
      title: "Language",
      pills: trackData.filters?.language?.map((language) => language.name) || [
        "",
      ], // Map language from filters
    },
  ];

  // function for play pause of the track
  const togglePlay = () => {
    // getting the data for the global player

    const trackInfo = {
      // trackImg: trackData.imageUrl,
      trackName: trackData?.name,
      trackArtistName:
        trackData?.primaryArtists && trackData?.primaryArtists.length > 0
          ? trackData.primaryArtists
              .map((artist) => artist.artistName)
              .join(", ")
          : "Artist Name",
      trackDuration: "03:25",
      trackCode: trackData?.trackCode,
      trackUrl: trackData?.mp3Link || getDirectLink(trackData?.link),
      waveJson: trackData?.waveformLink,
    };
    // console.log(trackInfo, "trackinfo");
    // console.log(isPlaying);

    // Show player if it's not already playing this track
    if (isPlaying === false || playerData.trackCode !== trackData.trackCode) {
      // console.log("start player");

      showPlayer(trackInfo);
    }

    // Toggle play/pause state
    setIsPlaying(!isPlaying);
    trackUserEvent("track-streamed");
  };

  // function to handle the request quote of the track
  const handleRequestQuote = () => {
    const licenseData = {
      title: trackData?.name,
      subTitle:
        trackData.primaryArtists && trackData.primaryArtists.length > 0
          ? trackData.primaryArtists
              .map((artist) => artist.artistName)
              .join(", ")
          : "",
      // img: trackData.imageUrl,
      img: config.s3imgUrl + "web/tracks/" + trackData.trackCode + ".webp",
      trackCode: trackData.trackCode,
    };
    setIsLicenseData(licenseData);
    // console.log(licenseData, "license data");
    setIsRequestOpen(true);
    // Track the request quote event
    trackUserEvent("requestquote-initiated");
  };

  // fucntion to add the track in the cart
  const handleCartClick = async () => {
    setLoadingCart(true); // Set loadingCart to true

    if (!isAuthenticated) {
      setIsModalOpen(true);
      setLoadingCart(false);
      return;
    }
    const cartInfo = {
      cardType: "track", // card type to display
      preText: trackData.name, // track name
      secText:
        trackData.primaryArtists && trackData.primaryArtists.length > 0
          ? trackData.primaryArtists
              .map((artist) => artist.artistName)
              .join(", ")
          : "", // artist name
      code: trackData.trackCode, // track code
      sp: trackData.SKU?.[0]?.sellingPrice.toLocaleString(), // selling price
      cp: trackData.SKU?.[0]?.costPrice.toLocaleString(), // cost price
      cardImg: trackData.imageUrl, // card img based on track
      trackJson: trackData.waveformLink,
      trackUrl: trackData.mp3Link || getDirectLink(trackData.link),
      skuID: trackData.SKU?.[0]?.id,
    };
    // console.log(cartInfo);

    // Get the current quantity for the track
    const currentQuantity = getItemQuantity(trackData.trackCode);

    const updatedQuantity = currentQuantity + 1; // Increment quantity
    // console.log("Updated Quantity:", updatedQuantity);

    // preparign the payload for api call
    const payload = {
      items: [
        { skuId: cartInfo.skuID || "", qty: updatedQuantity, cartType: "C" },
      ],
    };

    const addCart = await putCart(payload);
    // console.log(addCart, "cart add");
    if (addCart?.error?.code === 0) {
      // Dispatch action to add the trak in the redux
      dispatch(addToCart(cartInfo));
      setLoadingCart(false);
      showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg
      // Track the add to cart event
      trackUserEvent("addtocart");
      // pushToDataLayer({
      //   event: "addtocart",
      //   event_category: "ecommerce",
      //   event_action: "click",
      //   event_label: "Add to Cart",
      // });
    } else {
      setLoadingCart(false);
      showToast("Added to cart", "Failed to update the cart", "error"); //display toast msg
    }
  };

  // function to buy the track directly purchase
  const handleBuyNow = async () => {
    setLoadingBuyNow(true); // Set loadingBuyNow to true

    if (!isAuthenticated) {
      setIsModalOpen(true);
      setLoadingBuyNow(false);
      return;
    }
    // console.log(cartItems, "existing cart info");
    // Store existing cart data in localStorage
    // localStorage.setItem("prevcart", JSON.stringify(cartItems));

    // dispatch(clearCart()); // Dispatch clearCart

    // calling the cart api now
    const cartInfo = {
      cardType: "track", // card type to display
      preText: trackData.name, // track name
      secText:
        trackData.primaryArtists && trackData.primaryArtists.length > 0
          ? trackData.primaryArtists
              .map((artist) => artist.artistName)
              .join(", ")
          : "", // artist name
      code: trackData.trackCode, // track code
      sp: trackData.SKU?.[0]?.sellingPrice.toLocaleString(), // selling price
      cp: trackData.SKU?.[0]?.costPrice.toLocaleString(), // cost price
      cardImg: trackData.imageUrl, // card img based on track
      trackJson: trackData.waveformLink,
      trackUrl: trackData.mp3Link || getDirectLink(trackData.link),
      skuID: trackData.SKU?.[0]?.id,
    };
    // console.log(cartInfo);

    // Get the current quantity for the track
    // const currentQuantity = getItemQuantity(trackData.trackCode);

    // const updatedQuantity = currentQuantity + 1; // Increment quantity
    // console.log("Updated Quantity:", updatedQuantity);

    // preparign the payload for api call
    const payload = {
      items: [{ skuId: cartInfo.skuID || "", qty: 1, cartType: "B" }],
    };

    const addCart = await putCart(payload);
    // console.log(addCart, "cart add");
    if (addCart?.error?.code === 0) {
      // Dispatch action to add the trak in the redux
      dispatch(addToCart(cartInfo));
      showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg
      setLoadingBuyNow(false);
      // Track the buy now event
      trackUserEvent("buy-now");

      // Navigate to checkout on success
      navigate("/checkout", { state: { type: "B" } });
    } else {
      setLoadingBuyNow(false);
      // Retrieve previous cart data from localStorage and restore it to Redux
      // const prevCartData = JSON.parse(localStorage.getItem("prevcart")) || [];
      // prevCartData.forEach((item) => dispatch(addToCart(item)));
      showToast("Added to cart", "Failed to update the cart", "error"); //display toast msg
    }
  };

  // fucntion to add the track to the user my likes
  const handleTrackLike = async () => {
    try {
      // console.log(trackData.trackCode, "trackData");
      const payload = {
        typeCode: trackData.trackCode,
        type: "T",
        like: trackLikedStatus === "Y" ? "N" : "Y",
      };
      const trackLike = payload.like === "Y" ? "Y" : "N";
      setTrackLikeStatus(trackLike);
      const trackLikeResponse = await createUserLike(payload);
      // console.log(packLikeResponse, "packLikeResponse");
      if (trackLikeResponse?.error?.code === 0) {
        showToast("Like Added", "Likes Updated", "success"); //display toast msg

        // track liked event
        trackUserEvent("track-liked");
      } else {
        const trackLike = payload.like === "Y" ? "N" : "Y";
        setTrackLikeStatus(trackLike);
        showToast("Error", "Likes Updated Fail", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function to share link
  const handleShare = () => {
    handleShareClick();
    // Track the share event
    trackUserEvent("track-shared");
  };

  // function to get the  like status
  const fetchTrackLikeStatus = async () => {
    try {
      // prepareing payload
      const trackLikeStatus = await getTrackLike(trackCode);
      // console.log(trackLikeStatus);
      if (trackLikeStatus?.error?.code === 0) {
        const liked = trackLikeStatus.data.liked;
        // console.log(liked);
        setTrackLikeStatus(liked);
      } else {
        // console.log("else");
        setTrackLikeStatus("N");
      }
    } catch (error) {
      // console.log(error);
    }
  };

  // function to get the collection list
  const getCollections = async () => {
    try {
      const userPlaylist = await getUserCollections();

      if (userPlaylist?.error?.code === 0) {
        // console.log(userPlaylist, "user playlist");
        setPlaylists(userPlaylist?.data?.collections || []);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error fetching user playlist:", error);
    }
    setCreatePlaylistPopup(true);
  };

  // fucntion to create the collection
  const createNewCollection = async () => {
    // preparing the payload
    const payload = {
      name: inputPlaylistValue,
    };

    const createResponse = await createCollection(payload);

    if (createResponse?.error?.code === 0) {
      const playlist = {
        id: createResponse?.data?.id,
      };
      onPlaylistClick(playlist);
      // once playlist is created then add the track into the playlist and close the modal then
    } else {
      showToast("Like Added", "Failed to create collection", "error"); //display toast msg
    }
  };

  // fucntion to handle the track addition in existing playlist
  const onPlaylistClick = async (playlist) => {
    // console.log(playlist);
    // preparign the payload
    const payload = {
      id: playlist.id,
      type: "T",
      code: [trackData.trackCode],
    };

    // for adding track to playlists
    const addtrackResponse = await trackCollection(payload);
    // console.log(addtrackResponse, "506");
    if (addtrackResponse?.error?.code === 0) {
      console.log(addtrackResponse.data.name);

      // close the playlist popup modal
      handleClosePlaylistPopup();
      showToast(
        "Track Added",
        `${trackData.name} add to ${addtrackResponse?.data[0]?.name}`,
        "success"
      ); //display toast msg

      // track collection added event
      trackUserEvent("track-addtocollection");
    } else {
      showToast("Track Added", `Failed to add`, "error"); //display toast msg
    }
  };

  // fucntion to handle the playlist popup modal close
  const handleClosePlaylistPopup = () => {
    setCreateInputPlaylist(false);
    setPlaylists([]);
    setInputPlaylistValue("");
    setCreatePlaylistPopup(false);
  };

  // common function for track user event
  const trackUserEvent = (eventName) => {
    const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};
    trackEvent(eventName, {
      url: window.location.href,
      songName: trackData.name,
      userId: storedUserData.id,
      email: storedUserData.email,
      phone: storedUserData.mobile,
      type: "trackd",
      code: trackCode,
    });
  };

  // tabs button for details section
  const dataTabs =
    restrictionKeys.length > 0
      ? ["Usage Info", "Track Details", "Restrictions"]
      : ["Usage Info", "Track Details"];

  // closing modal on outside click of video modal
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setVideoModal(false);
    }
  };

  // ui for video modal
  const renderWatchVideo = () => {
    return (
      <div className={styles.modalOverlay} onClick={handleOverlayClick}>
        <div style={{ cursor: "pointer" }} onClick={() => setVideoModal(false)}>
          <IoMdCloseCircle className={styles.modalClose} />
        </div>
        <div className={styles.modalContainer}>
          <div className={styles.videoWrapper}>
            <iframe
              width="100%"
              height="100%"
              // src="https://www.youtube.com/watch?v=kEbtdY3BsyI"
              src="https://www.youtube.com/embed/kEbtdY3BsyI?autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              // allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  // restriction item to be visible in prcing section after slicing
  const getVisibleItems = () => {
    if (isMobile || windowWidth <= 430) return 1;
    if (isTablet) return 4;
    // if (windowWidth == 430) return 1;
    return 2;
  };

  // restricted in pricing section more text click
  const handleMoreClick = () => {
    setActiveTab("Restrictions");
    if (isMobile || isTablet) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={`${styles.container} ${styles.outerFull}`}>
      {/* video modal */}
      {videoModal && renderWatchVideo()}

      {/* rendering the create playlist ui popup */}
      {createPlaylistPopup && (
        <div className={styles.collectionContainer}>
          <div className={styles.collectionContent}>
            <div className={styles.popupHeading}>
              <p className={styles.pHText}>Add to Collection</p>
              <IoIosClose
                onClick={handleClosePlaylistPopup}
                size={30}
                cursor="pointer"
              />
            </div>
            <div className={styles.existingpsection}>
              {playlists.map((playlist) => (
                <div
                  key={playlist.id}
                  className={styles.existingCollection}
                  onClick={() => onPlaylistClick(playlist)} // Pass the clicked playlist info
                >
                  <div
                    style={{
                      backgroundImage: `url(${randomDefaultImage("pack")})`,
                    }}
                    className={styles.pimagecard}
                  ></div>
                  <div className={styles.playlistHeading}>
                    <p>
                      {playlist.name}
                      {""}
                    </p>
                    {/* <p>Tracks</p> */}
                  </div>
                </div>
              ))}
            </div>
            {createInputPlaylist && (
              <>
                <div>
                  <input
                    placeholder="Collection Name"
                    className={styles.cpinput}
                    value={inputPlaylistValue}
                    onChange={(e) => setInputPlaylistValue(e.target.value)}
                  />
                </div>
                <div>
                  <CustomButton
                    text="Create Collection"
                    backgroundColor="var(--button-primary)"
                    textColor="#fff"
                    fontWeight="600"
                    width="337px"
                    height="39px"
                    borderRadius="50px"
                    fontSize="14px"
                    onClick={createNewCollection}
                  />
                </div>
              </>
            )}
            {createInputPlaylist === false && (
              <div>
                <CustomButton
                  text="New Collection"
                  backgroundColor="var(--button-primary)"
                  textColor="#fff"
                  fontWeight="600"
                  width="337px"
                  height="39px"
                  borderRadius="50px"
                  fontSize="14px"
                  iconColor="#fff"
                  icon={<LuPlus />}
                  onClick={() => setCreateInputPlaylist(true)}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className={styles.inner}>
        <div className={`${styles.innerBreadcrumb}`}>
          <Link to={"/"}>Home</Link> /&nbsp;
          <Link to={"/tracks"}>Tracks</Link> /&nbsp;
          <span>{trackData?.name}</span>
        </div>
        {/* track details section */}
        <div className={styles.detailsContainer}>
          {/* info section */}
          <div className={styles.dContent}>
            <div className={`${styles.detailCard} ${styles.dcInfo}`}>
              <div
                className={styles.dcImg}
                style={{
                  backgroundImage: `url(${
                    config.s3imgUrl + "web/tracks/" + trackCode + ".webp" ||
                    randomDefaultImage("track")
                  })`,
                }}
              >
                <div className={styles.vocalTag}>
                  {trackData.vocal && trackData.vocal === "Y" && (
                    <img
                      className={styles.vocalTag}
                      src={config.s3imgUrl + "icons/vocalshadow.webp"}
                      title="Has Vocals"
                    />
                  )}
                </div>
                <div onClick={togglePlay} className={styles.plyBtnContainer}>
                  {isPlaying && playerData.trackCode === trackCode ? (
                    <FaPause />
                  ) : (
                    <FaPlay />
                  )}
                </div>
              </div>
              <div className={styles.infoData}>
                {/* name and sub text */}
                <div>
                  <p className={styles.iDHtxt}>{trackData.name}</p>
                  <div className={styles.stxtContainer}>
                    {trackData?.primaryArtists?.map((artist, index) => (
                      <Link
                        key={index}
                        to={`/search?searchKeyword=${encodeURIComponent(
                          artist.artistName
                        )}`}
                      >
                        <p className={styles.iDStxt}>
                          {artist.artistName}
                          {index < trackData.primaryArtists.length - 1
                            ? ", "
                            : ""}
                        </p>
                      </Link>
                    ))}
                  </div>
                </div>
                {/* more details and like share buttons */}
                <div className={styles.idbtnContainer}>
                  <div className={styles.icons}>
                    <div>
                      <CustomButton
                        isIconOnly={true}
                        backgroundColor="#EEEEEE"
                        borderRadius="50px"
                        iconColor="#000"
                        icon={
                          trackLikedStatus === "Y" ? (
                            <FaHeart style={{ color: "red" }} />
                          ) : (
                            <FaRegHeart />
                          )
                        }
                        width="34px"
                        height="34px"
                        iconSize="16px"
                        onClick={handleTrackLike}
                      />
                    </div>
                    <div>
                      <CustomButton
                        isIconOnly={true}
                        backgroundColor="#EEEEEE"
                        borderRadius="50px"
                        iconColor="#000"
                        icon={<FaLink />}
                        onClick={handleShare}
                        width="34px"
                        height="34px"
                        iconSize="16px"
                      />
                    </div>
                    <div>
                      <CustomButton
                        isIconOnly={true}
                        backgroundColor="#EEEEEE"
                        borderRadius="50px"
                        iconColor="#000"
                        icon={<BiAddToQueue />}
                        width="34px"
                        height="34px"
                        iconSize="16px"
                        onClick={getCollections}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* data section */}
            <div className={`${styles.detailCard} ${styles.dcMoreInfo}`}>
              <div className={styles.dcmBtnContainer}>
                <div className={styles.dbtnOuter}>
                  {dataTabs.map((tab) => (
                    <button
                      key={tab}
                      className={`${styles.dcmBtn} ${
                        activeTab === tab ? styles.activeBtn : ""
                      }`}
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
              </div>
              {/* data displaying based on btn active */}
              {activeTab === "Track Details" && (
                <div
                  className={`${styles.dcmData} ${styles.detailsData} ${
                    isExpanded ? styles.expanded : ""
                  }`}
                >
                  {/* pills section */}
                  <div className={styles.dtCard}>
                    {aboutSong
                      .filter(
                        (item) =>
                          item.pills &&
                          item.pills.length > 0 &&
                          item.pills[0].trim() && // Non-empty pills
                          ["Events", "Language", "Genres", "Category"].includes(
                            item.title
                          )
                      )
                      .map((item, index) => (
                        <div key={index}>
                          <p className={styles.ciHtxt}>{item.title}</p>
                          <div className={styles.ciPillSection}>
                            {item.pills.map((pill, pillIndex) => {
                              let linkTo = `/search?searchKeyword=${encodeURIComponent(
                                pill
                              )}&hs_ref=trackd-pills-${pill}`;
                              let pillData = null;

                              // Assign correct pillData based on item.title
                              if (item.title === "Genres") {
                                pillData =
                                  trackData.filters?.genre?.[pillIndex];
                              } else if (item.title === "Category") {
                                pillData =
                                  trackData.filters?.usecase?.[pillIndex];
                              } else if (item.title === "Language") {
                                pillData =
                                  trackData.filters?.language?.[pillIndex];
                              }

                              if (item.title === "Genres" && pillData) {
                                linkTo = `/genres/${encodeURIComponent(
                                  CleanSearchKey(pill)
                                )}/${pillData.id || ""}`;
                              } else if (
                                item.title === "Category" &&
                                pillData
                              ) {
                                linkTo = `/use-cases/${encodeURIComponent(
                                  CleanSearchKey(pill)
                                )}/${pillData.id || ""}`;
                              } else if (item.title === "Language") {
                                linkTo = `/languages/${encodeURIComponent(
                                  CleanSearchKey(pill)
                                )}/${pillData.id || ""}`;
                              }

                              return (
                                <Link key={pillIndex} to={linkTo}>
                                  <div className={styles.ciPills}>{pill}</div>
                                </Link>
                              );
                            })}
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* about and credit section */}
                  <div className={`${styles.dtCard} ${styles.dtgapclass}`}>
                    {aboutSong
                      .filter(
                        (item) =>
                          item.description?.trim() || // Non-empty description
                          (item.pills &&
                            item.pills.length > 0 &&
                            item.pills[0].trim() && // Non-empty pills
                            item.title !== "Events" &&
                            item.title !== "Language" &&
                            item.title !== "Genres" &&
                            item.title !== "Category")
                      )
                      .map((item, index) => (
                        <div key={index}>
                          <p className={styles.ciHtxt}>{item.title}</p>
                          {item.description && (
                            <p className={styles.cisubHtxt}>
                              {item.description}
                            </p>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {activeTab === "Usage Info" && (
                <div className={`${styles.dcmData} ${styles.uiContainer}`}>
                  <div className={styles.uiHeaderContainer}>
                    <div className={styles.uiHeader}>
                      <p className={styles.question}>
                        What is an organic short-form video?
                      </p>
                    </div>
                    <p className={styles.answer}>
                      A "short-form video" is a brief, under-60-second clip
                      shared on platforms like Instagram and YouTube. It
                      excludes paid promotion, relies on natural engagement, and
                      features no celebrities.
                    </p>
                    <div className={styles.sfbtn}>
                      <div className={styles.mdbtn}>
                        <CustomButton
                          text="Learn More"
                          width="132px"
                          height="20px"
                          backgroundColor="#f6fcff"
                          textColor="#000"
                          iconColor="#000"
                          icon={<MdOndemandVideo />}
                          borderRadius="14px"
                          fontSize="12px"
                          onClick={() => setVideoModal(true)}
                          iconSize="14"
                        />
                      </div>
                      <div>
                        <CustomButton
                          text="Full T&C"
                          width="109px"
                          height="20px"
                          backgroundColor="#f6fcff"
                          textColor="#000"
                          iconColor="#000"
                          icon={<FaExternalLinkAlt />}
                          borderRadius="14px"
                          fontSize="12px"
                          iconSize="12"
                          onClick={()=> navigate("/terms")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.access}>
                    <div className={`${styles.allowed} ${styles.accessCard}`}>
                      <p className={styles.accessHTxt}>What's Permitted</p>
                      {allowed.Y.map((item, index) => (
                        <div key={index} className={styles.accessItem}>
                          <div className={styles.allowedIcon}>
                            <IoIosCheckmark />
                          </div>
                          <p className={styles.accesstxt}>{item}</p>
                        </div>
                      ))}
                    </div>
                    <div className={`${styles.nallowed} ${styles.accessCard}`}>
                      <p className={styles.accessHTxt}>What's Prohibited</p>
                      {allowed.N.map((item, index) => (
                        <div key={index} className={styles.accessItem}>
                          <div className={styles.notallowedIcon}>
                            <IoIosClose />
                          </div>
                          <p className={styles.accesstxt}>{item}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "Restrictions" && (
                <div
                  className={`${styles.dcmData} ${styles.resContainer} ${
                    isExpanded ? styles.expanded : ""
                  }`}
                >
                  {restrictionKeys.map((key) => {
                    const restriction = restrictions[key];

                    return (
                      <div key={key} className={styles.rescard}>
                        <div className={styles.resHeader}>
                          <div className={styles.resIcon}>
                            <img
                              src={restriction.icon}
                              alt={restriction.name}
                              className={styles.resImg}
                            />
                            <div className={styles.rescross}>
                              <IoIosClose size={20} color="red" />
                            </div>
                          </div>
                          <p className={styles.resHtxt}>{restriction.name}</p>
                        </div>
                        <p className={styles.resSubTxt}>{restriction.desc}</p>
                      </div>
                    );
                  })}
                </div>
              )}
              {(isMobile || windowWidth <= 430) &&
                activeTab === "Track Details" && (
                  <div
                    className={styles.vmorebtnContainer}
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    <p>{isExpanded ? "View Less" : "View More"}</p>
                    <IoIosArrowDown
                      style={{
                        transform: isExpanded
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
          {/* pricing section */}
          <div className={styles.dPricing}>
            {/* short form video card */}
            <div className={`${styles.dpCard} ${styles.dpshortCard}`}>
              <div className={styles.dpscardContent}>
                <div>
                  <p className={styles.cardHTxt}>Short Form Video Use</p>
                  <p className={styles.cardSubTxt}>
                    For use in 1 organic video such as Instagram Reels or YT
                    Shorts only
                  </p>
                </div>
                {sellingPrice && (
                  <div>
                    <div className={styles.pdata}>
                      <span className={styles.prupee}>₹</span>
                      <p className={styles.pprice}>
                        {sellingPrice
                          ? sellingPrice.toLocaleString("en-IN")
                          : ""}
                      </p>
                    </div>
                    <div className={`${styles.pdata} ${styles.gapclass}`}>
                      <p className={styles.pcpamt}>
                        ₹{costPrice ? costPrice.toLocaleString("en-IN") : ""}
                      </p>
                      <p className={styles.pdiscount}>
                        {discountPercent ? discountPercent : ""}
                      </p>
                    </div>
                    <p className={styles.inclusive}>Inclusive of All Taxes</p>
                  </div>
                )}
              </div>
              <div className={styles.dpscardContent}>
                {/* card btn section */}
                {sellingPrice && costPrice ? (
                  <>
                    <div>
                      <CustomButton
                        text={loadingCart ? "Please Wait" : "Add to Cart"}
                        backgroundColor="#F5F5F5"
                        textColor="#1B1B1B"
                        fontWeight="550"
                        width={
                          windowWidth <= 320
                            ? "117px"
                            : windowWidth <= 768
                            ? "160px"
                            : "190px"
                        }
                        height="39px"
                        borderRadius="50px"
                        fontSize="14px"
                        onClick={handleCartClick}
                        loader={loadingCart ? true : false}
                        iconColor="#000"
                      />
                    </div>
                    <div>
                      <CustomButton
                        text={loadingBuyNow ? "Please Wait" : "License Now"}
                        backgroundColor="var(--button-primary)"
                        textColor="#fff"
                        fontWeight="550"
                        width={
                          windowWidth <= 320
                            ? "117px"
                            : windowWidth <= 768
                            ? "160px"
                            : "190px"
                        }
                        height="39px"
                        borderRadius="50px"
                        fontSize="14px"
                        onClick={handleBuyNow}
                        loader={loadingBuyNow ? true : false}
                        iconColor="#fff"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <CustomButton
                        text="Request a Quote"
                        backgroundColor="#F5F5F5"
                        textColor="#1B1B1B"
                        fontWeight="550"
                        width={
                          windowWidth <= 320
                            ? "117px"
                            : windowWidth <= 768
                            ? "160px"
                            : "190px"
                        }
                        height="39px"
                        borderRadius="50px"
                        fontSize="14px"
                        onClick={handleRequestQuote}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* broadcast card */}
            <div className={`${styles.dpCard} ${styles.dpbroadCard}`}>
              <div className={styles.dpbscardContent}>
                <p className={styles.dbcardHTxt}>
                  TV, OTT or Broadcast License
                </p>
                <p className={styles.dbcardSubTxt}>
                  Get custom quote for use in long-form video content for TV,
                  OTT or broadcast platforms
                </p>
              </div>
              {/* card btn section */}
              <div className={styles.dpscardContent}>
                <CustomButton
                  text="Request a Quote"
                  backgroundColor="#F5F5F5"
                  textColor="#1B1B1B"
                  fontWeight="550"
                  width="190px"
                  height="39px"
                  borderRadius="50px"
                  fontSize="14px"
                  onClick={handleRequestQuote}
                />
              </div>
            </div>
            {/* restriction section */}
            {restrictionKeys.length > 0 ? (
              <div className={styles.presContainer}>
                <p className={styles.presHtxt}>Restricted Categories</p>
                <div className={styles.pres} ref={containerRef}>
                  {restrictionKeys.slice(0, getVisibleItems()).map((key) => {
                    const restriction = restrictions[key];
                    return (
                      <div key={key} className={styles.presCard}>
                        <img
                          src={restriction.icon}
                          alt={restriction.name}
                          className={styles.presImg}
                        />
                        <p className={styles.pcresHtxt}>{restriction.name}</p>
                      </div>
                    );
                  })}
                  {/* Display Count Only If There Are Hidden Items */}
                  {hiddenCount > 0 && (
                    <div className={styles.presCountContainer}>
                      <span>+</span>
                      <p onClick={handleMoreClick} className={styles.presCount}>
                        {hiddenCount} More
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.presContainer}>
                <p className={styles.presHtxt}>Restricted Categories</p>
                <div className={styles.noCard}>
                  <div className={styles.allowedIcon}>
                    <IoIosCheckmark />
                  </div>{" "}
                  <p className={styles.pcresHtxt}>
                    No brand category restrictions
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* cats based tracks and (track in packs) section */}
        <div className={styles.catsTracks}>
          {packCard?.length > 0 && (
            <div>
              <p className={styles.packHtxt}>Explore Packs with this Track</p>
              <div className={styles.descriptionP}>
                <p className={styles.dpHtxt}>
                  Buy this track at a discounted price in these packs
                </p>
                <div className={styles.viewall}>
                  <p
                    onClick={() => navigate("/packs")}
                    className={styles.vaTxt}
                  >
                    View All
                  </p>
                  <p className={styles.vaTxt}>
                    <IoIosArrowForward color="#373737" />
                  </p>
                </div>
              </div>
              <div className={styles.catsCards}>
                {/* Apply Carousel only for Mobile & Tablet (≤781px) */}
                {windowWidth <= 481 ? (
                  <Carousel gap={20} autoScroll={false} scrollInterval={4000}>
                    {packCard.map((pack, index) => (
                      <PackCard key={index} packCards={[pack]} packType="pop" />
                    ))}
                  </Carousel>
                ) : (
                  <PackCard packCards={packCard} packType="similartrackpack" />
                )}
              </div>
            </div>
          )}

          {/* explore similar tracks by usecases */}
          {useCaseTracks.length > 0 && (
            <div>
              <p className={styles.strackHtxt}>
                Explore More{" "}
                {/* {trackData?.filters?.usecase?.[1]?.name.length > 40
                  ? trackData?.filters?.usecase?.[1]?.name.substring(0, 40) +
                    "..."
                  : trackData?.filters?.usecase?.[1]?.name}{" "}
                Tracks */}
                {trackData?.filters?.usecase?.[0]?.name || ""} Tracks
              </p>
              <div className={styles.catsCards}>
                <TrackCard
                  trackCard={
                    windowWidth <= 481
                      ? useCaseTracks.slice(0, 4)
                      : useCaseTracks
                  }
                  btnWidth={
                    isMobile
                      ? windowWidth <= 320
                        ? "80px"
                        : windowWidth <= 375
                        ? "100px"
                        : "110px"
                      : isTablet
                      ? windowWidth <= 430
                        ? "110px"
                        : "168px"
                      : "186px"
                  }
                  trackType="catsbtrack"
                />
              </div>
            </div>
          )}

          {/* explore similar tracks by artists */}
          {artistTracks.length > 0 && (
            <div>
              <p className={styles.strackHtxt}>
                Explore More Tracks by{" "}
                {trackData.primaryArtists
                  .map((artist) => artist.artistName)
                  .join(", ").length > 40
                  ? trackData.primaryArtists
                      .map((artist) => artist.artistName)
                      .join(", ")
                      .substring(0, 40) + "..."
                  : trackData.primaryArtists
                      .map((artist) => artist.artistName)
                      .join(", ")}
              </p>
              <div
                className={`${styles.catsCards} ${
                  (windowWidth <= 481 ? artistTracks.slice(0, 4) : artistTracks)
                    .length <= 2
                    ? styles.catscardslice
                    : ""
                }`}
              >
                <TrackCard
                  trackCard={
                    windowWidth <= 481 ? artistTracks.slice(0, 4) : artistTracks
                  }
                  btnWidth={
                    isMobile
                      ? windowWidth <= 320
                        ? "80px"
                        : windowWidth <= 375
                        ? "100px"
                        : "110px"
                      : isTablet
                      ? windowWidth <= 430
                        ? "110px"
                        : "168px"
                      : "186px"
                  }
                  trackType="catsbtrack"
                />
              </div>
            </div>
          )}

          {/* explore similar tracks by events */}
          {eventTracks.length > 0 && (
            <div>
              <p className={styles.strackHtxt}>
                {/* Explore More{" "}
                {trackData?.events?.join(", ").length > 40
                  ? trackData.events.join(", ").substring(0, 40) + "..."
                  : trackData.events.join(", ")}{" "}
                Tracks */}
                Explore More {trackData?.events?.[0] || ""} Tracks
              </p>
              <div className={styles.catsCards}>
                <TrackCard
                  trackCard={
                    windowWidth <= 481 ? eventTracks.slice(0, 4) : eventTracks
                  }
                  btnWidth={
                    isMobile
                      ? windowWidth <= 320
                        ? "80px"
                        : windowWidth <= 375
                        ? "100px"
                        : "110px"
                      : isTablet
                      ? windowWidth <= 430
                        ? "110px"
                        : "168px"
                      : "186px"
                  }
                  trackType="catsbtrack"
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* request quote screen */}
      <RequestLicense
        isOpen={isRequestOpen}
        onClose={() => setIsRequestOpen(false)}
        licenseData={licenseData}
      />
      {/* login modal popup */}
      <Login isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default TrackPage;
